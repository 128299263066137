<template>
  <section class="body">
    <section class="banner">
      <div class="summary">
        <h3>智能整车控制决策单元<strong>VCU</strong></h3>
        <ul class="tags">
          <li>Autosar架构</li>
          <li>全功能接口</li>
          <li>ASIL-D最高安全等级</li>
        </ul>
      </div>
      <img class="img" src="../../assets/vbd/home/vcu.png" width="1140" height="720" />
    </section>

    <section class="content">
      <dl class="item">
        <dt>矩阵智能整车控制决策单元</dt>
        <dd>
          <p class="h"><strong>ASIL-D</strong>最高等级车规安全认证</p>
          <ul class="list">
            <li>英飞凌/恩智浦/瑞萨多平台兼容车规级MCU</li>
            <li>AEC-Q100车规级品质认证</li>
            <li>ASIL D最高等级车规安全认证</li>
            <li>Grade 1车规级耐温等级认（-40℃~125℃）</li>
            <li>符合ISO26262功能安全要求</li>
          </ul>
        </dd>
      </dl>
      <dl class="item">
        <dt>软硬件解耦架构快速适配开发</dt>
        <dd>
          <img class="img" src="../../assets/vbd/vcu/img_1.png" width="234" height="86" />
          <ul class="list">
            <li>AutoSar™ 标准协议开发</li>
            <li>EcoCoder（快速原型开发），支持整车控制系统的所有典型输入/输出BSW快速封装</li>
            <li>完美兼容Simulink开发环境，用户可以用100%基于模型开发控制策略</li>
          </ul>
        </dd>
      </dl>
      <dl class="item">
        <dt>底层软件封装标定部署简单</dt>
        <dd>
          <img class="img" src="../../assets/vbd/vcu/img_2.png" width="215" height="74" />
          <ul class="list">
            <li>底层软件架构隔离封装</li>
            <li>上层控制策略（应用）可基于Simulink模型代码生成开发，或更高自由度的手工代码开发</li>
            <li>支持多种主流标定工具（CCP/XCP），如INCA，CANape，及矩阵自研发的专业标定工具软件。</li>
          </ul>
        </dd>
      </dl>
      <dl class="item r2">
        <dt>多种电气架构适配 ｜ 标准UDS诊断协议</dt>
        <dd>
          <div class="imgWrap">
            <img class="img" src="../../assets/vbd/vcu/img_3.png" width="390" height="212" />
          </div>
          <ul class="list">
            <li>高鲁棒性的系统架构设计以及能量管理算法和控制策略</li>
            <li>能够通过加载不同软件算法及控制策略实现，对不同新能源架构车型适配：混合动力车型、纯电动车型、增车式车型</li>
            <li>标准的UDS故障诊断协议，在网子系统的所有部件（如BCM、MCU等）故障诊断策略</li>
          </ul>
        </dd>
      </dl>
      <dl class="item">
        <dt>丰富的硬件接口配置</dt>
        <dd>
          <ul class="list">
            <li>121pin全功能接口</li>
            <li>5路高速CAN通讯线路</li>
            <li>自定义车内网络安全分层拓扑</li>
            <li>26路可自定义高低边驱动</li>
            <li>3A大电流直驱输出</li>
            <li>针对PDU优化的12V高效直驱</li>
            <li>29路ADC接口</li>
            <li>6路PWM接口</li>
            <li>1路232外部测试通信接口</li>
          </ul>
        </dd>
      </dl>
    </section>

    <section class="ports">
      <div class="layout">
        <h3>VCU硬件接口</h3>
      </div>
    </section>

    <div class="layout">
      <section class="box">
        <h3>VCU产品优势</h3>
        <div class="diff">
          <table width="100%" cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="22%" />
              <col width="46%" />
              <col width="32%" />
            </colgroup>
            <thead>
              <tr>
                <th class="title">竞争对比</th>
                <th>参数对比</th>
                <th>分析</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="title">
                  <div class="product nc">
                    <img class="logo" src="../../assets/vbd/vcu/logo_nc.png" width="90" height="40" />
                    <img class="pic" src="../../assets/vbd/vcu/vcu_nc.png" width="160" height="120" />
                  </div>
                </td>
                <td>
                  <ul class="list">
                    <li>主控制器:国产杰发科技AC7811 100M 主频 汽车级 32位VCU</li>
                    <li>安全等级ASIL B</li>
                    <li>工作温度-40℃-125℃</li>
                    <li>通信接口，2路CAN通信，具有CAN总线唤醒功能</li>
                    <li>6路模拟信号输入，可输入信号范围0~14V</li>
                    <li>10路数字信号输入，可输0~5V或14V电平信号或PWM信号</li>
                    <li>8路低边驱动，最高500mA驱动电流</li>
                    <li>7路高边驱动，最高1A驱动电流</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>安全等级低</li>
                    <li>芯片稳定性差</li>
                    <li>驱动控制输出能力差</li>
                    <li>底层软件未作隔离，上层策略应用，二次开发难度大</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="title">
                  <div class="product nxp">
                    <img class="logo" src="../../assets/vbd/vcu/logo_nxp.png" width="110" height="46" />
                    <img class="pic" src="../../assets/vbd/vcu/vcu_nxp.png" width="178" height="90" />
                  </div>
                </td>
                <td>
                  <ul class="list">
                    <li>主控制器：NXP  S32K312 120M主频汽车级 32位VCU</li>
                    <li>安全等级ASIL D</li>
                    <li>工作温度-40℃-125℃</li>
                    <li>数字量输入接口数量 19 路</li>
                    <li>模拟量输入接口数量 15 路</li>
                    <li>高边驱动接口数量 6 路 单路最大输出电流 2.4A</li>
                    <li>低边驱动接口数量 12 路 单路最大输出电流 350mA</li>
                    <li>CAN 接口数量 3 路 CAN 2.0A/B 唤醒信号接口数量 4 路</li>
                  </ul>
                </td>
                <td>
                  <ul class="list">
                    <li>接口功能单一</li>
                    <li>驱动控制输出能力差</li>
                    <li>性价比低</li>
                    <li>软硬件解耦能力差</li>
                  </ul>
                </td>
              </tr>
              <tr class="high">
                <td class="title">
                  <div class="product juzhen">
                    <img class="logo" src="../../assets/vbd/vcu/logo_juzhen.png" width="100" height="100" />
                    <img class="pic" src="../../assets/vbd/vcu/vcu_juzhen.png" width="180" height="130" />
                  </div>
                </td>
                <td>
                  <ul class="list">
                    <li>英飞凌 TC1782 120M 主频 多核汽车级 32位VCU<br />（软硬件解耦，兼容NXP、Renesas 平台方案）</li>
                    <li>AEC-Q100车规级品质认证</li>
                    <li>ASIL D最高等级车规安全认证</li>
                    <li>Grade 1车规级耐温等级认</li>
                    <li>121pin全功能接口</li>
                    <li>5路高速CAN通讯线路</li>
                    <li>自定义车内网络安全分层拓扑</li>
                    <li>26路可自定义高低边驱动</li>
                    <li>3A大电流直驱输出</li>
                    <li>针对PDU优化的12V高效直驱</li>
                    <li>29路ADC接口</li>
                    <li>6路PWM接口</li>
                    <li>1路232外部测试通信接口</li>
                  </ul>
                </td>
                <td>
                  <ul class="list nb">
                    <li>全功能接口，宽电流输出</li>
                    <li>底层软件模块化封装，上层控制策略（应用）开发简单</li>
                    <li>AutoSar™ 标准协议开发</li>
                    <li>ASIL-D最高车规安全等级</li>
                    <li>高鲁棒性的系统架构设计以及能量管理算法和控制策略</li>
                    <li>能够通过加载不同软件算法及控制策略实现，对不同新能源架构车型适配</li>
                    <li>标准的UDS故障诊断协议，支持在网设备诊断通信</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="box">
        <h3>交付方案</h3>

        <div class="plans">
          <div class="item">
            <div class="wrap">
              <h3>方案1</h3>
              <p class="desc">硬件+工具链协议对接适配交付，保证客户高效，快速，方便的对车型适配安装</p>
              <dl>
                <dt>
                  <img class="img" src="../../assets/vbd/vcu/plan_img.png" width="206" height="130" />
                  <p>VCU硬件</p>
                </dt>
                <dd>
                  <img class="img" src="../../assets/vbd/vcu/plan_1_1.png" width="100" height="100" />
                  <p>软硬件模块封装</p>
                </dd>
                <dd>
                  <img class="img" src="../../assets/vbd/vcu/plan_1_2.png" width="100" height="100" />
                  <p>软硬件工具链</p>
                </dd>
              </dl>
            </div>
          </div>
          <div class="item">
            <div class="wrap">
              <h3>方案2</h3>
              <p class="desc">硬件“保姆级”交付，提供VCU硬件适配，整车通信架构设计，整车通信协议栈设计，整车控制策略设计等整套车辆控制“交钥匙”解决方案</p>
              <dl>
                <dt>
                  <img class="img" src="../../assets/vbd/vcu/plan_img.png" width="206" height="130" />
                  <p>VCU硬件</p>
                </dt>
                <dd>
                  <img class="img" src="../../assets/vbd/vcu/plan_2_1.png" width="100" height="100" />
                  <p>整车通信架构</p>
                </dd>
                <dd>
                  <img class="img" src="../../assets/vbd/vcu/plan_2_2.png" width="100" height="100" />
                  <p>整车通信协议栈控制策略</p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section class="box">
        <h3>成功案例</h3>
        <div class="cases">
          <div class="item">
            <img class="img" src="../../assets/vbd/vcu/pic_1.png" width="1076" height="300" />
            <h4>为豪驰量产车型提供整车控制解决方案</h4>
            <ul class="list">
              <li>精简整车电气架构</li>
              <li>提高整车通信负载能力、整车通信效率</li>
              <li>降低整车电气零部件成本</li>
            </ul>
          </div>
          <div class="item">
            <img class="img" src="../../assets/vbd/vcu/pic_2.png" width="430" height="280" />
            <p>为矩阵量产无人车D1、线控底盘<br />提供整车控制决策解决方案</p>
          </div>
        </div>

        <ul class="partner">
          <li><img src="../../assets/vbd/vcu/logo_1.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_2.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_3.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_4.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_5.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_6.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_7.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_8.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_9.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_10.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_11.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_12.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_13.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_14.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_15.png" width="158" height="74" /></li>
          <li><img src="../../assets/vbd/vcu/logo_16.png" width="158" height="74" /></li>
          <!-- 卡位防错乱 -->
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
          <li class="empty"></li>
        </ul>

      </section>

      <section class="box">
        <h3>规格参数</h3>

        <div class="params">
          <table cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <thead>
              <tr>
                <th class="first">规格项</th>
                <th>参数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="first">标称电压</td>
                <td>12V</td>
              </tr>
              <tr>
                <td class="first">工作电压</td>
                <td>9V~16V DC</td>
              </tr>
              <tr>
                <td class="first" rowspan="3">环境参数</td>
                <td>工作温度：—40℃~125℃</td>
              </tr>
              <tr>
                <td>储存温度：-40~150℃</td>
              </tr>
              <tr>
                <td>相对湿度：10~95%RH</td>
              </tr>
              <tr>
                <td class="first">工作电流</td>
                <td>≤10A</td>
              </tr>
              <tr>
                <td class="first">静态能耗</td>
                <td>≤10mA / 0.1W</td>
              </tr>
              <tr>
                <td class="first" rowspan="3">唤醒方式</td>
                <td>1、IG_12V唤醒</td>
              </tr>
              <tr>
                <td>2、CHARGE唤醒</td>
              </tr>
              <tr>
                <td>3、CAN唤醒</td>
              </tr>
              <tr>
                <td class="first">PWM</td>
                <td>6路PWM输出</td>
              </tr>
              <tr>
                <td class="first">高低边驱动能力</td>
                <td>共26路高低可配置驱动接口（最大可达3A）</td>
              </tr>
            </tbody>
          </table>
          <table cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <thead>
              <tr>
                <th class="first">规格项</th>
                <th>参数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="first" rowspan="3">信号采集</td>
                <td>2路5V高精度信号采集</td>
              </tr>
              <tr>
                <td>13路12V高精度信号采集</td>
              </tr>
              <tr>
                <td>14路地信号采集</td>
              </tr>
              <tr>
                <td class="first">启动时间</td>
                <td>≤200ms 整车控制器从加电运行到实现实时数据采集的时间</td>
              </tr>
              <tr>
                <td class="first">CAN通信</td>
                <td>5路CAN通信，CAN 2.0B标准，符合SAE J1939<br />波特率：500KB，最大支持1M"</td>
              </tr>
              <tr>
                <td class="first">故障储存</td>
                <td>故障分级储存，故障信息及故障快照</td>
              </tr>
              <tr>
                <td class="first">电路失效</td>
                <td>在“过压、短接、过流、过载、短路”等电路失效下具有强鲁棒性特点</td>
              </tr>
              <tr>
                <td class="first">防护等级</td>
                <td>IP67</td>
              </tr>
              <tr>
                <td class="first">OTA</td>
                <td>具有远程标定及固件烧写功能，支持固件转发烧录（如对VCU网络下BSM等设备升级）</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "VCU",
};
</script>

<style lang="scss" scoped>
.layout {
  max-width: 1480px;
}

.list {
  margin-left: 24px;
  font-size: 20px;
  line-height: 26px;
  list-style: disc outside;

  &.nb {
    color: #ff3f00;
  }

  li {
    padding: 5px 0;
    // text-align: justify;
  }
}

.banner {
  display: flex;
  padding-top: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 850px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #FF3F00;

  .summary {
    font-size: 24px;
    line-height: 28px;
    transform: translateX(-50%);

    h3 {
      font-size: 60px;
      color: #fff;
      font-weight: 300;
      line-height: 64px;

      strong {
        display: block;
        padding-top: 16px;
        font-size: 220px;
        line-height: 248px;
        font-weight: 900;
        font-family: Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
      }
    }

    .tags {
      position: relative;
      top: 60px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;

      li {
        margin: 10px;
        padding: 0 18px;
        font-size: 22px;
        line-height: 38px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
  }

  .img {
    position: absolute;
    transform: translate(360px, 65px);
  }
}

.content {
  display: flex;
  margin: 0 auto;
  padding: 120px 20px 30px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1480px;

  .item {
    margin: 32px 0;
    width: 28%;
    min-width: 410px;

    &.r2 {
      width: 60%;
      // min-width: 860px;

      dd {
        display: flex;
        flex-direction: row-reverse;

        .list {
          flex: 1;
          margin-right: 10%;
        }

        .imgWrap {
          flex: 1;

          .img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    dt {
      margin-bottom: 32px;
      font-size: 30px;
      line-height: 40px;
    }

    dd {
      .h {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 36px;

        strong {
          display: block;
          font-size: 78px;
          line-height: 1;
        }
      }

      .img {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}

.ports {
  height: 300px;
  background: #1a1a1a url(../../assets/vbd/vcu/vcu.jpg) 50% no-repeat;
  background-size: cover;

  .layout {
    display: flex;
    padding: 40px 0;
    height: 300px;
    align-items: flex-end;

    h3 {
      font-size: 30px;
      vertical-align: text-bottom;
      color: #fff;
    }
  }
}

.box {
  margin: 100px 0;

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 36px;
  }
}

.diff {
  table {
    th, td {
      padding: 16px 3.6%;
      font-size: 20px;
      line-height: 26px;
      border: 1px solid #999;
    }

    th {
      font-size: 24px;
      line-height: 30px;
    }

    td {
      padding-top: 28px;
      padding-bottom: 28px;
      vertical-align: top;
    }

    .title {
      text-align: center;
    }

    .product {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 0;
      
      .logo {
        margin-bottom: 24px;
      }
    }

    .high {
      td {
        background-color: #fdf0e7;
      }
    }
  }
}

.plans {
  display: flex;
  counter-reset: plan;

  .item {
    flex: 4;
    position: relative;
    overflow: hidden;
    background-color: #e9f5f6;
    border-radius: 18px;

    &::before {
      position: absolute;
      bottom: -90px;
      right: 68px;
      font-size: 580px;
      color: #fff;
      line-height: 1;
      font-family: Arial, Helvetica, sans-serif;
      counter-increment: plan;
      content: counter(plan);
    }

    & + .item {
      margin-left: 36px;
      flex: 5;
    }

    .wrap {
      position: relative;
      padding: 76px 48px;
      font-size: 20px;
      line-height: 30px;

      h3 {
        font-size: 26px;
        line-height: 40px;
      }

      dl {
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        dt, dd {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
        }

        dt {
          width: 206px;
        }

        dd {
          align-items: center;

          .img {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.cases {
  display: flex;
  padding: 40px 0;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    line-height: 30px;

    h4 {
      font-size: 22px;
      line-height: 30px;
    }

    .img {
      width: 100%;
      height: auto;
      transform: translateX(-16.728%);
    }

    .list {
      display: flex;

      li {
        margin-right: 60px;
      }
    }

    & + .item {
      text-align: center;
      transform: translateX(7.44%);

      .img {
        transform: translateX(0);
      }
    }
  }
}

.partner {
  display: flex;
  margin: 20px -10px 0;
  justify-content: space-around;
  flex-wrap: wrap;

  li {
    margin: 12px 10px;
    width: 160px;
    height: 76px;
    border: 1px solid #c8c9ca;

    img {
      display: block;
    }
  }

  .empty {
    margin: 0 10px;
    height: 0;
    border: none;
  }
}

.params {
  display: flex;

  table {
    flex: 1;
    font-size: 20px;
    line-height: 26px;

    & + table {
      margin-left: 2.5%;
    }

    th, td {
      padding: 10px 4%;

      &.first {
        padding-left: 6%;
        padding-right: 0;
        border-right: 2px solid #fff;
      }
    }

    th {
      color: #fff;
      background-color: #e73e0c;
    }

    tbody {
      td {
        word-break: break-all;
      }

      tr:nth-child(odd) {
        td {
          background-color: #eeeeef;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .list {
    margin-left: 24px;
    font-size: 14px;
    line-height: 20px;

    li {
      padding: 3px 0;
      // text-align: justify;
    }
  }

  .banner {
    display: flex;
    height: auto;
    min-height: 100vh;
    padding: 120px 0 60px;
    flex-direction: column-reverse;

    .summary {
      font-size: 12px;
      line-height: 16px;
      transform: none;

      h3 {
        font-size: 30px;
        line-height: 32px;

        strong {
          padding-top: 8px;
          font-size: 110px;
          line-height: 124px;
        }
      }

      .tags {
        top: 0;
        padding: 20px 0 0;

        li {
          margin: 3px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
        }
      }
    }

    .img {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: block;
    padding: 60px 20px 0;

    .item {
      margin: 0 0 60px;
      width: auto;
      min-width: auto;

      &.r2 {
        width: auto;
        // min-width: 860px;

        dd {
          flex-direction: column-reverse;

          .list {
            margin-right: 0;
          }

          .imgWrap {
            padding: 16px 20px 0;

            .img {
              margin: 0;
            }
          }
        }
      }

      dt {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 30px;
      }

      dd {
        .h {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 24px;

          strong {
            font-size: 36px;
          }
        }

        .img {
          margin: 24px auto 16px;
        }
      }
    }
  }

  .ports {
    height: 80px;

    .layout {
      padding: 16px 10px;
      height: 80px;

      h3 {
        font-size: 16px;
      }
    }
  }

  .box {
    margin: 48px 0;

    h3 {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .diff {
    table {
      th, td {
        padding: 8px;
        font-size: 12px;
        line-height: 16px;
      }

      th {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .title {
        text-align: center;
      }

      .product {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 0;
        
        
        .logo {
          margin-bottom: 12px;
          height: auto;
        }

        .pic {
          height: auto;
        }

        &.nc {
          .logo {
            width: 45px;
          }

          .pic {
            width: 80px;
          }
        }
        &.nxp {
          .logo {
            width: 55px;
          }

          .pic {
            width: 89px;
          }
        }
        &.juzhen {
          .logo {
            width: 50px;
          }

          .pic {
            width: 90px;
          }
        }
      }

      .high {
        td {
          background-color: #fdf0e7;
        }
      }
    }

    .list {
      margin-left: 14px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .plans {
    display: block;

    .item {
      &::before {
        bottom: -64px;
        right: 0px;
        font-size: 400px;
      }

      & + .item {
        margin: 20px 0 0;
      }

      .wrap {
        position: relative;
        padding: 24px;
        font-size: 14px;
        line-height: 20px;

        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        dl {
          padding-top: 12px;
          justify-content: space-around;

          dt {
            width: 100%;

            .img {
              width: 150px;
              height: auto;
            }
          }
        }
      }
    }
  }

  .cases {
    display: block;
    padding: 20px 0;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
      line-height: 16px;

      h4 {
        margin: 8px 0 4px;
        font-size: 14px;
        line-height: 20px;
      }

      .img {
        width: 100%;
        height: auto;
        transform: translateX(-5%);
      }

      .list {
        margin-left: 16px;
        font-size: 12px;
        line-height: 16px;

        li {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      & + .item {
        margin-top: 24px;
        transform: none;

        .img {
          margin: 0 auto 12px;
          width: 60%;
        }
      }
    }
  }

  .partner {
    display: flex;
    margin: 20px -4px 0;
    justify-content: space-around;
    flex-wrap: wrap;

    li {
      margin: 4px;
      width: 81px;
      height: 39px;

      img {
        width: 79px;
        height: 37px;
      }
    }

    .empty {
      margin: 0 4px;
    }
  }

  .params {
    flex-direction: column;

    table {
      flex: 1;
      font-size: 12px;
      line-height: 16px;

      & + table {
        margin: 0;

        thead {
          display: none;
        }
      }

      th, td {
        padding: 8px 4%;

        &.first {
          padding-left: 4%;
          border-right: 1px solid #fff;
        }
      }
    }
  }
}
</style>